import React from "react";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyImage = ({
  source,
  scrollPosition,
  alt,
  className,
  height,
  width,
}) => {
  return (
    <LazyLoadImage
      effect="blur"
      wrapperClassName={className}
      src={source}
      width={width || "auto"}
      height={height || "auto"}
      alt={alt}
      scrollPosition={scrollPosition}
      style={{
        display: "flex",
      }}
    />
  );
};

export default trackWindowScroll(LazyImage);
