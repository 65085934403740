import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Logo from "../../../images/v2/PC-logo.svg";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Link from "../Link";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import salesManagementImg from "../../../images/v2/home/product-dropdown/sales_pipeline_menu.svg";
import integrationImg from "../../../images/v2/home/product-dropdown/integration_menu.svg";
import securityImg from "../../../images/v2/home/product-dropdown/security_menu.svg";
import whatsappMessage from "../../../images/v2/home/features-images/whatsAppCrmDropdown.png";
import analyticsAndInsites from "../../../images/v2/home/features-images/analytics-dropdown.svg";
import leadCapture from "../../../images/v2/home/features-images/lead-capture-dropdwon.svg";
import inboxImg from "../../../images/v2/home/product-dropdown/inbox_menu.svg";
import chat from "../../../images/v2/home/product-dropdown/chat.svg";
import liveCircle from "../../../images/v2/home/livedot.png";
import whatsappCrm from "../../../images/v2/home/whatsapp-crm.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Drawer from "./Drawer";
import HeaderTypography from "../Typography/HeaderTypography";
import ParagraphTypography from "../Typography/ParagraphTypography";
import { useContactUsDialog } from "../ContactUsDialog";
import useWidth from "../../../hooks/useWidth";
import quotationSpanImg from "../../../images/v2/xero/quotation-bg-img.svg";
import companyLogo from "../../../images/v4/logos/companyLogo.svg";
import clsx from "clsx";
import { color, motion } from "framer-motion";

import whatsappMenuIcon from "../../../images/v4/navbarIcons/whatsappMenuIcon.svg";
import securityMenuIcon from "../../../images/v4/navbarIcons/securityMenuIcon.svg";
import salesMenuIcon from "../../../images/v4/navbarIcons/salesMenuIcon.svg";
import leadsMenuIcon from "../../../images/v4/navbarIcons/leadsMenuIcon.svg";
import integrationMenuIcon from "../../../images/v4/navbarIcons/integrationMenuIcon.svg";
import inboxMenuIcon from "../../../images/v4/navbarIcons/inboxMenuIcon.svg";
import channelMenuIcon from "../../../images/v4/navbarIcons/channelMenuIcon.svg";
import analyticsMenuIcon from "../../../images/v4/navbarIcons/analyticsMenuIcon.svg";
import aiMenuIcon from "../../../images/v4/navbarIcons/aiMenuIcon.svg";
import caretDownArrow from "../../../images/v4/navbarIcons/caretDownArrow.svg";
import closeMenuIcon from "../../../images/v4/navbarIcons/closeMenuIcon.svg";
import hamburgerIcon from "../../../images/v4/navbarIcons/hamburgerIcon.svg";
import LazyImage from "../../v4/LazyLoadImage/LazyImage";

const useStyles = makeStyles((theme) => ({
  annoucementBannerRoot: {
    background: " linear-gradient(96deg, #37690a 50%, #428b02 83%);",
  },
  annoucementBannerHeight: {
    padding: ".25rem",
  },
  bannerText: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
    },
  },
  joinUsButton: {
    fontSize: 20,
    fontWeight: 600,
    marginLeft: 36,
    padding: "0px 12px",
    borderRadius: "0px",
    // padding: "0px",
    lineHeight: "normal",
    border: "1px solid #315CB1",
    borderWidth: "0 0 1px 0",
  },
  annoucementBannerClose: {
    color: "#2E3F4F",
  },
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    fontSize: 18,
    color: "#000 !important",
    padding: "6px 12px",
  },
  startBtn: {
    fontWeight: 600,
    fontSize: 18,
    minWidth: 130,
    color: "#fff",
    "&:hover": {
      color: "#000000",
    },
    [theme.breakpoints.between(0, 505)]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  btnMargin: {
    marginRight: theme.spacing(1),
    color: "#000000",
    border: "1px solid rgb(0 0 0 / 62%)",
  },
  productButton: {
    fontSize: 18,
    color: "#000",
  },
  productWrapper: {
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  paper: {
    padding: theme.spacing(1),
  },
  phone: {
    color: "#7c98b6",
    margin: 6,
    fontSize: 19,
  },
  contact: {
    marginRight: 18,
    [theme.breakpoints.between(0, 505)]: {
      marginRight: 0,
    },
  },
  expandIcon: {
    color: "#516f90",
  },
  productFeatureListItem: {
    marginBottom: ".75rem",
    "&:hover": {
      boxShadow: "rgba(51,71,91,0.12) 0 0 10px -3px",
    },
  },
  featureItemContainer: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  link: {
    color: "#ffe600",
    paddingLeft: ".5rem",
    textDecoration: "underline",
    "&:hover": {
      color: "#ffe600",
    },
  },
  quotationSpan: {
    display: "inline-block",
    fontWeight: "bold",
    background: `url(${quotationSpanImg}) no-repeat`,
    width: "142px",
    height: "54px",
    verticalAlign: "middle",
    lineHeight: "49px",
    fontSize: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "38px",
      backgroundSize: "100%",
      width: "118px",
      height: "44px",
    },
  },
  whatsappcrmImg: {
    "@media(max-width:768px)": {
      display: "none",
    },
  },
  liveCircleImage: {
    height: "10px",
    width: "10px",
    position: "relative",
    background: "#fdca47",
    borderRadius: "50%",
    " &:after": {
      content: '""',
      position: "absolute",
      width: "20px",
      height: "20px",
      background: "#fdca47",
      opacity: "0.3",
      top: "-5px",
      left: "-5px",
      borderRadius: "50%",
      animation: "$live 1.5s infinite ease ",
    },
  },
  "@keyframes live": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(.8)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  bannerContent: {
    "@media(max-width:768px)": {
      marginRight: "0!important",
    },
    "@media(max-width:600px)": {
      fontSize: "14px",
    },
    "@media(max-width:520px)": {
      fontSize: "13px",
    },
    "@media(max-width:460px)": {
      fontSize: "12px",
    },
    "@media(max-width:380px)": {
      fontSize: "10px",
    },
  },
  headerContainer: {
    width: "100%",
    margin: "auto",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    zIndex: "100",
    transition: "0.5s ease",
    "&.hideNavbar": {
      top: "-100px",
    },
    "@media only screen and (max-width: 1000px)": {
      display: "none",
    },
  },
  headerContainerInner: {
    position: "relative",
    width: "100%",
    padding: "0px 32px",
    maxWidth: "1200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "rgb(7 34 53 / 90%)",
    backdropFilter: "blur(10px)",
    // "transparent linear-gradient(90deg, #011D32 0%, #023053 100%) 0% 0% no-repeat padding-box",
    borderRadius: "0px 0px 16px 16px",
    transition: "0.3s ease",
    "&.glassEffect": {
      background: "rgb(7 34 53 / 70%)",
      backdropFilter: "blur(7px) contrast(1)",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    "&.darkBackground": {
      background: "rgb(7 34 53 / 100%)",
    },
    // "&::before": {
    //   content: "''",
    //   position: "absolute",
    //   width: "100%",
    //   top:'0',
    //   left:'0',
    //   zIndex: "-1",
    //   height: "100%",
    //   backdropFilter: "blur(10px)",
    // },
  },
  leftNavigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "80px",
  },
  navigationList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "32px",
  },
  subMenu: {
    gap: "14px",
    top: "99%",
    left: "50%",
    width: "80%",
    display: "flex",
    opacity: "0",
    padding: "20px",
    rowGap: "14px",
    position: "absolute",
    flexWrap: "wrap",
    transform: "translate(-50%, 0%)",
    background: "rgb(7 34 53 / 100%)",
    visibility: "hidden",
    alignItems: "flex-start",
    transition: "0.5s ease",
    borderRadius: "0 0  20px 20px",
    backdropFilter: "blur(10px)",
    justifyContent: "space-between",
    "&.active": {
      visibility: "visible",
      opacity: 1,
    },
    "&::before": {
      width: "25px",
      top: "0",
      left: "-25px",
      height: "51px",
      content: '""',
      position: "absolute",
      boxShadow: "0 -25px 0 0 rgb(7 34 53 / 100%)",
      backgroundColor: "transparent",
      borderTopRightRadius: "20px",
    },
    "&::after": {
      top: "0",
      right: "-25px",
      width: "25px",
      height: "51px",
      content: '""',
      zIndex: 0,
      position: "absolute",
      boxShadow: "0 -25px 0 0 rgb(7 34 53 / 100%)",
      backgroundColor: "transparent",
      borderTopLeftRadius: "20px",
    },
    "&.glassEffect": {
      background: "rgb(0 0 0 / 40%)",
      backdropFilter: "blur(10px)",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      "&::before": {
        boxShadow: "0 -25px 0 0 rgb(0 0 0 / 40%)",
        backgroundColor: "transparent",
        backdropFilter: "blur(10px)",
      },
      "&::after": {
        zIndex: "-1",
        boxShadow: "0 -25px 0 0 rgb(0 0 0 / 40%)",
        backgroundColor: "transparent",
        backdropFilter: "blur(10px)",
      },
    },
  },
  navigationItem: {
    color: "white",
    display: "flex",
    padding: "32px 0",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none !important",
      color: "white !important",
    },
    // "&.hoverMenu:hover": {
    //   "& $subMenu": {
    //     visibility: "visible",
    //     opacity: 1,
    //   },
    // },
  },

  subMenuOption: {
    width: "31%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textDecoration: "none",
    gap: "15px",
    padding: "10px",
    transition: "0.5s",
    "& img": {
      width: "48px",
      height: "48px",
    },
    "&:hover": {
      background: "#0F2F42",
      textDecoration: "none",
      borderRadius: "15px",
    },
  },
  subMenuOptionContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "12px",
  },
  subMenuHeading: {
    textDecoration: "none",
  },
  subMenuDesc: {
    textDecoration: "none",
  },
  mobMenuContainerOuter: {
    position: "sticky",
    top: "0",
    zIndex: "200",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media only screen and (min-width: 1000px)": {
      display: "none",
    },
  },
  mobMenu: {
    background: "#072235",
    width: "100%",
    // borderRadius: "0 0 20px 20px",
    margin: "0 auto",
    padding: "12px 20px",
    position: "relative",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    zIndex: "100",
  },
  mobMenuContainer: {
    width: "100%",
    height: "100%",
    zIndex: "101",
    top: 0,
    left: "-100%",
    position: "fixed",
    backdropFilter: "blur(10px)",
    background: "#072235f0",
    padding: "12px 20px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    gap: "24px",
    flexDirection: "column",
    transition: "0.3s ease",
    "&.active": {
      visibility: "visible",
      opacity: "1",
      left: "0",
    },
    "& $hamburger": {
      left: 0,
    },
  },
  mobMenuListContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    // gap: "25px",
    height: "100%",
    alignItems: "flex-start",
    // maxHeight: "85%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  mobMenuListItem: {
    fontSize: "20px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    marginBottom: "15px",
    textDecoration: "none",
    width: "100%",
    "&:hover": {
      color: "white",
      textDecoration: "none",
    },
  },
  subMenuOptionMobile: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textDecoration: "none",
    gap: "15px",
    "& img": {
      width: "24px",
      height: "24px",
    },
    "&:hover": {
      textDecoration: "none",
    },
    "& $subMenuDesc": {
      fontSize: "12px",
    },
    "& $subMenuHeading": {
      fontSize: "14px",
    },
  },
  mobSubMenuContainer: {
    display: "none",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "15px",
    flexDirection: "column",
    width: "100%",
    height: "0",
    visibility: "hidden",
    opacity: "0",
    transition: "0.5s ease",
    "&.active": {
      height: "max-content",
      display: "flex",
      visibility: "visible",
      opacity: "1",
      marginBottom: "15px",
    },
  },
  rightNavigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "10px",
    "& .primaryButton": {
      zIndex: "2",
    },
  },
  downArrow: {
    transition: "0.3s ease",
    "&.rotate": {
      transform: "rotate(180deg)",
    },
  },
  hamburger: {
    position: "absolute",
    left: "20px",
    top: "50%",
    transform: "translate(0%, -40%)",
  },
  mobMenuContainerHeader: {
    display: "flex",
    width: "100%",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  mobMenuFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& a": {
      width: "48%",
    },
  },
}));

export const productFeatureList = [
  {
    title: "WhatsApp CRM",
    img: whatsappMenuIcon,
    description: "Integrate with WhatsApp and close deals right from your CRM.",
    path: "/whatsapp-crm",
    altText: "Whatsapp CRM",
  },
  {
    title: "AssistAI",
    img: aiMenuIcon,
    description:
      "Automate sales and convert chats into won deals with AssistAI intelligence.",
    path: "/sales-crm-features/ai-crm-tool-for-small-business",
    altText: "Assist AI - AI CRM",
  },
  {
    title: "Multichannel communication",
    img: channelMenuIcon,
    description:
      "Merge communications channels and converse via a single platform.",
    path: "/sales-crm-features/multichannel-integration-for-sales-marketing",
    altText: "Multichannel Integration",
  },
  {
    title: "Sales management",
    img: salesMenuIcon,
    description: "Organise your leads and opportunities with sales pipelines.",
    path: "/sales-pipeline-management-tool",
    altText: "Sales management",
  },
  {
    title: "Lead capture",
    img: leadsMenuIcon,
    description: "Capture leads from web forms, incoming messages, and more.",
    path: "/lead-capture-generation-webform",
    altText: "Lead capture form",
  },
  {
    title: "Analytics",
    img: analyticsMenuIcon,
    description:
      "Track performance and sales visibility through visual dashboards.",
    path: "/sales-dashboard",
    altText: "Analytics",
  },
  {
    title: "Security",
    img: securityMenuIcon,
    description: "Safeguard your data with multi-layered security.",
    path: "/crm-data-security-protection",
    altText: "Secure CRM",
  },
  {
    title: "Integrations",
    img: integrationMenuIcon,
    description: "Communicate efficiently via messages, emails, and more.",
    path: "/crm-integrations",
    altText: "CRM integrations",
  },
  {
    title: "Inbox",
    img: inboxMenuIcon,
    description: "Access emails and contacts from your CRM inbox.",
    path: "/crm-email-inbox-integration",
    altText: "Email inbox",
  },
];

const Index = ({ pathname }) => {
  const width = useWidth();
  const classes = useStyles();

  const isSPGPage = pathname === "/psg-grant" || pathname === "/psg-grant/";

  const [anchorEl, setAnchorEl] = useState(null);
  // const [contactAnchorEl, setContactAnchorEl] = useState(null);
  const [setOpenTrial] = useContactUsDialog();
  const [showBanner, setShowBanner] = useState(true);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  // const handleClick = (event) => {
  // 	setContactAnchorEl(event.currentTarget);
  // };
  //
  // const handleClose = () => {
  // 	setContactAnchorEl(null);
  // };

  // useEffect(() => {
  // 	const annoucementBannerClosed = window.localStorage.getItem("annoucementBannerClosed");
  // 	setShowBanner(!(annoucementBannerClosed === "true"));
  // }, []);

  const handleCloseAnnoucementBanner = () => {
    setShowBanner(false);
    // window.localStorage.setItem("annoucementBannerClosed", "true");
  };

  const glowForm = (ev) => {
    ev.preventDefault();
    const form = document.getElementById("psg-form");
    form.scrollIntoView({ behavior: "smooth", block: "end" });
    form.classList.remove("glow");
    form.classList.add("glow");
    setTimeout(() => {
      form.classList.remove("glow");
    }, 3200);
  };

  const open = Boolean(anchorEl);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollPosition(currentScrollY);
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        // setSubMenuOpen(false);
        // setIsVisible(false);
      } else {
        // setSubMenuOpen(false);
        // setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  const [isDesktopView, setisDesktopView] = useState(true);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [mobSubMenuOpen, setMobSubMenuOpen] = useState(false);
  const [mobMenuOpen, setMobMenu] = useState(false);

  const subMenuToggler = () => {
    if (subMenuOpen) {
      setSubMenuOpen(false);
    } else {
      setSubMenuOpen(true);
    }
  };
  const subMenuOpenToggle = () => {
    setSubMenuOpen(true);
  };
  const subMenuCloseToggle = () => {
    setSubMenuOpen(false);
  };
  useEffect(() => {
    if (window.innerWidth > 1000) {
      setisDesktopView(true);
    } else {
      setisDesktopView(false);
    }
  }, []);

  // useEffect(() => {
  //   if (mobMenuOpen || subMenuOpen) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [mobMenuOpen, subMenuOpen]);

  const openMenu = () => {
    setMobMenu(true);
    const button = document.getElementById("wa-widget-send-button");
    button.classList.remove("open");
    document.getElementsByClassName("wa-chat-box")[0].style.display = "none";
  };

  // useEffect(() => {
  //   if (
  //     pathname === "/sales-crm-features/ai-crm-tool-for-small-business" ||
  //     pathname == "/sales-crm-features/ai-crm-tool-for-small-business/"
  //   ) {
  //     document.getElementById("mobMenuContainerOuter").style.position = "fixed";
  //   } else {
  //     document.getElementById("mobMenuContainerOuter").style.position =
  //       "sticky";
  //   }
  // }, []);

  return (
    <>
      <div
        className={clsx(classes.headerContainer, isVisible ? "" : "hideNavbar")}
      >
        <div
          onClick={subMenuCloseToggle}
          className={`blurScreen ${subMenuOpen ? "active" : ""}`}
        ></div>
        <div
          className={clsx(
            classes.headerContainerInner,
            subMenuOpen ? "darkBackground" : "",
            scrollPosition > 200 && !subMenuOpen ? "glassEffect" : ""
          )}
        >
          <div className={classes.leftNavigation}>
            <Link to="/">
              <img
                src={companyLogo}
                alt="Pepper Cloud - Best Sales CRM Software"
              />
            </Link>
            <div className={classes.navigationList}>
              <Link
                to="/sales-crm-features/ai-crm-tool-for-small-business"
                className={clsx(classes.navigationItem, "font-wix-regular")}
              >
                AssistAI
                {/* <div className="blikingTag">New</div> */}
              </Link>
              <div
                className={clsx(classes.navigationItem, "font-wix-regular")}
                onMouseEnter={subMenuOpenToggle}
                // onMouseLeave={subMenuCloseToggle}
              >
                Features{" "}
                <img
                  src={caretDownArrow}
                  className={clsx(
                    classes.downArrow,
                    subMenuOpen ? "rotate" : ""
                  )}
                  alt="CRM feature"
                />
              </div>
              <Link
                to="https://blog.peppercloud.com/"
                target="_blank"
                className={clsx(classes.navigationItem, "font-wix-regular")}
              >
                Blogs
              </Link>
              <Link
                to="/careers"
                className={clsx(classes.navigationItem, "font-wix-regular")}
              >
                Careers
              </Link>
              <Link
                to="/crm-platfrom-pricing"
                className={clsx(classes.navigationItem, "font-wix-regular")}
              >
                Pricing
              </Link>
              <Link
                to="/psg-grant"
                className={clsx(classes.navigationItem, "font-wix-regular")}
              >
                PSG grant
              </Link>
            </div>
          </div>
          <div className={classes.rightNavigation}>
            <Link
              to="https://app.peppercloud.com/login"
              target="_blank"
              className="loginButton font-wix-regular font-white"
            >
              Log in
            </Link>
            <Link
              className="primaryButton font-wix-regular font-white"
              to="/contact"
              target="_blank"
            >
              Get demo
            </Link>
          </div>
          <div
            className={clsx(classes.subMenu, subMenuOpen ? "active" : "")}
            onMouseLeave={subMenuCloseToggle}
          >
            {productFeatureList.map((feature, index) => (
              <Link
                className={classes.subMenuOption}
                to={feature.path}
                onClick={subMenuCloseToggle}
              >
                <LazyImage
                  width={"48px"}
                  height={"48px"}
                  source={feature.img}
                  alt={feature.altText}
                />
                <div className={classes.subMenuOptionContent}>
                  <p
                    className={clsx(
                      classes.subMenuHeading,
                      "font-wix-medium",
                      "font-14",
                      "color-black7"
                    )}
                  >
                    {feature.title}
                  </p>
                  <p
                    className={clsx(
                      classes.subMenuDesc,
                      "font-wix-regular",
                      "font-14",
                      "color-black5"
                    )}
                  >
                    {feature.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className={classes.mobMenuContainerOuter} id="mobMenuContainerOuter">
        <div className={classes.mobMenu}>
          <div className={classes.hamburger} onClick={() => openMenu()}>
            <img src={hamburgerIcon} />
          </div>
          <Link to="/" onClick={() => setMobMenu(false)}>
            <img src={companyLogo} />
          </Link>
          <div
            className={clsx(
              classes.mobMenuContainer,
              mobMenuOpen ? "active" : ""
            )}
          >
            <div className={classes.mobMenuContainerHeader}>
              <div
                className={classes.hamburger}
                onClick={() => setMobMenu(false)}
              >
                <img src={closeMenuIcon} />
              </div>
              <img src={companyLogo} />
            </div>
            <div className={classes.mobMenuListContainer}>
              <Link
                to="/sales-crm-features/ai-crm-tool-for-small-business"
                onClick={() => setMobMenu(false)}
                className={clsx(classes.mobMenuListItem, "font-wix-regular")}
              >
                AssistAI
                {/* <div className="blikingTag">New</div> */}
              </Link>
              <div
                className={clsx(classes.mobMenuListItem, "font-wix-regular")}
                onClick={() => setMobSubMenuOpen(!mobSubMenuOpen)}
              >
                Features
                <img
                  className={clsx(
                    classes.downArrow,
                    mobSubMenuOpen ? "rotate" : ""
                  )}
                  alt="CRM feature"
                  src={caretDownArrow}
                />
              </div>
              <div
                className={clsx(
                  classes.mobSubMenuContainer,
                  mobSubMenuOpen ? "active" : ""
                )}
              >
                {productFeatureList.map((feature, index) => (
                  <Link
                    to={feature.path}
                    onClick={() => setMobMenu(false)}
                    className={classes.subMenuOptionMobile}
                  >
                    <img src={feature.img} alt={feature.altText} />
                    <div className={classes.subMenuOptionContent}>
                      <p
                        className={clsx(
                          classes.subMenuHeading,
                          "font-wix-medium",
                          "color-black7"
                        )}
                      >
                        {feature.title}
                      </p>
                      <p
                        className={clsx(
                          classes.subMenuDesc,
                          "font-wix-regular",
                          "color-black5"
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
              <Link
                to="https://blog.peppercloud.com/"
                target="_blank"
                onClick={() => setMobMenu(false)}
                className={clsx(classes.mobMenuListItem, "font-wix-regular")}
              >
                Blogs
              </Link>
              <Link
                to="/careers"
                onClick={() => setMobMenu(false)}
                className={clsx(classes.mobMenuListItem, "font-wix-regular")}
              >
                Careers
              </Link>
              <Link
                to="/crm-platfrom-pricing"
                onClick={() => setMobMenu(false)}
                className={clsx(classes.mobMenuListItem, "font-wix-regular")}
              >
                Pricing
              </Link>
              <Link
                to="/psg-grant"
                onClick={() => setMobMenu(false)}
                className={clsx(classes.mobMenuListItem, "font-wix-regular")}
              >
                PSG grant
              </Link>
            </div>
            <div className={classes.mobMenuFooter}>
              <Link
                to="https://app.peppercloud.com/login"
                target="_blank"
                onClick={() => setMobMenu(false)}
                className="loginButton font-wix-regular font-white"
              >
                Log in
              </Link>
              <Link
                className="primaryButton font-wix-regular font-white"
                to="/contact"
                onClick={() => setMobMenu(false)}
                target="_blank"
              >
                Get demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
